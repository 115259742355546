<template>
  <div class="flex flex-col card">
    <div>
      <div class="py-3 text-lg">
        <!--TODO: need to make resposive :/-->
        <!--You need to point your domain to our servers in TODO: <a class="underline" href="" target="_blank">your registrar's DNS settings</a>.-->
        You need to point your domain to our servers in your registrar's DNS settings.
      </div>
      <div class="text-lg py-3">
        Specifically, add an <span class="text-red-900"><strong>A</strong></span> <span class="text-red-900 font-bold">Record</span>, for the hostname<span class="text-red-900 font-bold "><strong> @</strong></span>,
        with the the value <span class="text-red-900 font-bold">144.126.214.20</span>
      </div>
      <div class="text-sm py-3">
       It should look something like this in your Registar's DNS settings for your domain:
      </div>
    </div>
    <div v-if="true" class="flex flex-col border">
      <table class="table-fixed">
        <thead>
          <tr>
            <th class="px-4 py-4">type</th>
            <th class="px-4 py-4">host/name/hostname</th>
            <th class="px-4 py-4">value/data/IP</th>
            <th class="px-4 py-4">TTL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="">A</td>
            <td class="">@</td>
            <td class="">144.126.214.20</td>
            <td class="">60 </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-sm pt-10">
      Once added, your deployment will be automatically updated here, available and https-enabled.
    </div>
    <div class="pt-2 text-sm">
      Note: TTL refers to the number of seconds for DNS changes to take effect. 
    </div>
    <div class="text-xs">
      (DNS changes can still take different amounts of time to take effect, regardless of the TTL setting)
    </div>
    <div v-if="!postDeploy" class="pt-4 text-sm">
       If you have added the above records to your registrar's DNS settings, you can close this page and check back later.
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: function() {
    return {
    };
  },
  props: {
    postDeploy: [Boolean, null]
  }
};
</script>

<style scoped>
.card {
    display: flex;
    margin: 0 auto;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
  }
</style>
