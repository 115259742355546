const host = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000'
  : 'https://spinup.dev'

export default {
  postFiles(files, domain={}, entireDir=false, action='create') {
    let creds = window.localStorage.getItem('spinupuseremail') ? 'include' : 'omit'

    const data = new FormData();

    if (entireDir) {
      files.forEach(file => data.append(file.webkitRelativePath, file));
    } else {
      files.forEach(file => data.append(file.name, file)); // first val, keys need to be unique, I think
    }

    if (domain['subdomain']) { data.append('subdomain', domain['subdomain']) }
    if (domain['custom_domain']) { data.append('custom_domain', domain['custom_domain']) }
    data.append('entireDir', entireDir)

    switch (action) {
      case 'create':
        return fetch(`${host}/create`, {
          credentials: creds,
          method: 'post',
          headers: {
          },
          body: data
        })
      case 'update':
        return fetch(`${host}/deployment/update/${domain['deploy_link']}`, {
          credentials: 'include',
          method: 'post',
          headers: {
          },
          body: data
        })
      default:
        return
    }
  },
  checkSubdomainAvailability(subdomain) {
    return fetch(`${host}/subdomains/${subdomain}?isAvailable=true`, {
      method: 'get',
      headers: {
      }
   })
  },
  getCloudInfo(code, deploy_link) {
    return fetch(`${host}/deployment/${deploy_link}`, {
      credentials: 'include',
      method: 'get',
      headers: {
        auth: code
      }
    })
  },
  getUser(code, email) {
    return fetch(`${host}/user/${email}`, {
      credentials: 'include',
      method: 'get',
      headers: {
        auth: code
      }
    })
  },
  destroy(deploy_link){
    // for now, deletes deploy, in the future can keep it toggle / it redoloy, or not, but not have to do a new distro, still expires at same time though

    return fetch(`${host}/deployment/destroy/${deploy_link}`, {
      credentials: 'include',
      method: 'post',
      headers: {
      }
    })
  },
  getAnalytics(deploy_link) {
    return fetch(`${host}/analytics/${deploy_link}`, {
      credentials: 'include',
      method: 'get',
      headers: {
      }
    })
  },
  pingActiveUsers(deploy_link) {
    return fetch(`${host}/live_visitors/${deploy_link}`, {
      //credentials: 'include',
      method: 'get',
      headers: {
      }
    })
  },
  getAccessLink(email, deploy_link){
    return fetch(`${host}/access/${deploy_link}`, {
      method: 'post',
      headers: {
      },
      body: JSON.stringify({ email: email })
    })
  },
  getAccountAccessLink(email){
    return fetch(`${host}/user_access`, {
      method: 'post',
      headers: {
      },
      body: JSON.stringify({ email: email })
    })
  },
  postCheckout(plan) {
    return fetch(`${host}/create-checkout-session`, {
      method: 'post',
      credentials: 'include',
      headers: {
      },
      body: JSON.stringify({plan: plan})
    })
  },
  postSuccess(id) {
    return fetch(`${host}/subscribe_success`, {
      method: 'post',
      credentials: 'include',
      headers: {
      },
      body: JSON.stringify({ id: id })
    })
  },
  resolved(domain) {
    let complete = true
    return fetch(`${host}/resolved?complete=${complete}`, {
      //credentials: 'include',
      method: 'post',
      headers: {
      },
      body: domain
    })
  },
  authd_resolved(domain) {
    let complete = true
    return fetch(`${host}/resolved/?complete=${complete}`, {
      credentials: 'include',
      method: 'post',
      headers: {
      },
      body: domain
    })
  },
}
