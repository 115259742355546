<template id="">
<div class="signup-container mt-6" :class="shakeContainer ? 'shake' : ''">
    <div class="w-full mx-auto font-sans">
    <form class="bg-white rounded-lg pt-0 pb-8 mb-4">
      <div class="flex m-auto">
        <div class="flex-auto px-1">
          <input v-model="email" type="text" placeholder="your@email.com" class="bg-gray-200 appearance-none border-2 border-gray-200 h-3/4 rounded py-2 px-1 text-gray-700 focus:outline-none focus:bg-white focus:border-green-500" />
          <input type="text" hidden style="display:none;">
        </div>
        <div class="px-1 flex-auto">
          <button class="whitespace-nowrap shadow bg-green-500 hover:bg-green-600 focus:shadow-outline focus:outline-none h-3/4 text-white font-bold py-0 px-4 rounded " @click="getLink" type="button">
            Get Link
          </button>
        </div>
      </div>
      <div class="text-xs">
        You need to confirm your email to manage this deployment <span id="email-info" style="" class="text-xs ml-1 px-1 bg-gray-100	rounded ">i</span>
      </div>
      <div class="pt-3">
        <template v-if="postSubmitMessage" >
          <div class="text-sm">
            check your email!
          </div>
        </template>
      </div>
      <div class="text-xs pb-4">
      </div>
    </form>
  </div>
</div>
</template>
<script charset="utf-8">
import requests from '@/requests/requests.js'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default {
  components: {
  },
  props: {
    deploy_link: String
  },
  data: function () {
    return {
      email: '',
      shakeContainer: false,
      postSubmitMessage: false
    }
  },
  mounted() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 13) {
        this.getLink()
        event.preventDefault();
      }
    })

    // throws "leave site?" dialogue box on tab close
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
     // Chrome requires returnValue to be set
      e.returnValue = '';
    });

    tippy('#email-info', {
        content: "otherwise, you won't be able to acess this page again and this deployment will expire in ~1 hour",
      });

    this.$nextTick(() => {
      this.$bus.on('hoverOnDisabled', (data) => {
        setTimeout(() => {
          this.shakeContainer = true 
        }, 600)

        setTimeout(() => {
          this.shakeContainer = false
        }, 2000)
      })
    })
  },
  methods: {
    getLink() {
      if (this.email === '') {
        alert('please enter a valid email address')
        return
      }
       
      requests.getAccessLink(this.email, this.deploy_link).then(response => response.json())
        .then((body) => {
        })
      this.postSubmitMessage = true
    }
  }
}
  
</script>

<style type="text/css" media="screen">

.signup-container {
    position: fixed;
    right: 25px;
    top: 0;
}

.shake {
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
