<template>
  <div class="flex-col text-left mx-4">
    <div class="flex-col">
      <main class="">
        <h1 class="text-xl font-bold">About</h1>
          todo
          <!--<section class="py-4">-->
          <!--SpinUp is <span class="font-bold">free</span> to start and use right away. With more advanced use, you will need the <button @click="showBiller = true" class="underline">Standard Plan</button>-->
          <!--for a small maintenance fee.-->
      </main>
      <aside class="mt-6">
        <a href="/" target="" class="underline">Deploy your project</a>
      </aside>
    </div>
    <Biller v-if="showBiller" :show="showBiller" :hideSubscribe="true" />
  </div>
</template>

<script>

import Biller from '@/components/Biller.vue'

export default {
  components: {
    Biller
  },
  props: {
  },
  data: function() {
    return {
      showBiller: false
    };
  },
}
</script>

<style>

</style>
