<template id="">
<div class="grid mx-10 ">
    <div class="bg-white rounded-lg shadow-md border border-gray-200 hover:shadow-lg duration-500 px-2 sm:px-6 md:px-2 py-4 my-6">
        <div class="grid grid-cols-12 gap-3">
            <div class="col-span-0 sm:col-span-2 text-center hidden sm:block">
                <!--<div class="grid my-3">-->
                    <!--<span class="inline-block font-bold text-xs">-->
                        <!--1213 Unique Visitors-->
                    <!--</span>-->
                <!--</div>-->
                <div class="grid my-3">
                    <span class="inline-block font-bold text-xs">
                      Deployed to <a class="underline" :href="'https://' + deployment" target="_blank">{{deployment}}</a>
                    </span>
                </div>
            </div>

            <div class="col-span-12 sm:col-start-3 sm:col-end-13 px-3 sm:px-0">

                <div class="mt-2">
                  <router-link class="underline" :to="{ name: 'Cloud', params: { deploy_link: deployment }}" > Manage {{deployment}}
                  </router-link>
                </div>

                <div class="grid grid-cols-2 mt-4 my-auto">
                    <!-- Tags  -->
                    <div class="col-span-12 lg:col-span-8">
                        <!--<a href="#" class="inline-block rounded-full text-white -->
                            <!--bg-red-400  -->
                            <!--text-xs font-bold -->
                            <!--mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 -->
                            <!--opacity-90 ">-->
                            <!--Caching-->
                        <!--</a>-->
                        <a href="#" class="inline-block rounded-full text-white
                            bg-yellow-400
                            text-xs font-bold
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1
                            opacity-90 ">
                            Hobby
                        </a>
                        <!--<a href="#" class="inline-block rounded-full text-white-->
                            <!--bg-green-400-->
                            <!--text-xs font-bold-->
                            <!--mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1-->
                            <!--opacity-90 ">-->
                            <!--Live-->
                        <!--</a>-->
                        <!--<a href="#" class="inline-block rounded-full text-white -->
                            <!--bg-blue-400 -->
                            <!--text-xs font-bold -->
                            <!--mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 -->
                            <!--opacity-90 hover:opacity-100">-->
                            <!--CDN-enabled-->
                        <!--</a>-->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script >
export default {
  props: {
    deployment: String,
  }
}
</script>
