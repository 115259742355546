<template>
  <div v-if="deployLink">
    <CurlRequestPostSignUp :subdomain="subdomain" :customDomain="customDomain" :deployLink="deployLink"/>
  </div>
  <div v-else>
    <CurlRequestPreSignUp :subdomain="subdomain" :customDomain="customDomain" />
  </div>
</template>

<script>
import CurlRequestPostSignUp from './curl_request/CurlRequestPostSignUp.vue'
import CurlRequestPreSignUp from './curl_request/CurlRequestPreSignUp.vue'

export default {
  components: {
    CurlRequestPreSignUp,
    CurlRequestPostSignUp
  },
  data: function() {
    return {
      greeting: ""
    };
  },
  props: {
    deployLink: String,
    subdomain: String,
    customDomain: String
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
