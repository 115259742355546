<template id="">
<body class="">
  <div class="max-w-xs mx-auto mt-8 px-4 font-sans">
    <form class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
      <h3 class="title text-xl mb-8 mx-auto text-center font-bold text-green-700">Account Login</h3>
      <div class="mb-4">
        <label for="something" class="block text-gray-500 font-medium text-sm mb-2">We'll send you an access link</label>
        <input v-model="email" type="text" placeholder="your@email.com" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500" />
        <input type="text" hidden style="display:none;">
        <div class="">
          <button class="shadow bg-green-500 hover:bg-green-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mt-4" @click="getLink" type="button">
            Get Link
          </button>
          <div class="pt-3">
            {{postSubmitMessage}}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div>
    <div>
      
    </div>
    <div>
      Want to signup? <a href="/" class="underline">Deploy a new project</a>
    </div>
    <div class="text-xs">
      (and you can signup once it is deployed)
    </div>
  </div>
</body>

</template>

<script charset="utf-8">

import requests from '@/requests/requests.js'
export default {
  data: function () {
    return {
      postSubmitMessage: '',
      email: '',
      subdomain: ''
    }
  },
  props: {
    cloudLink: String,
    deployLink: String,
  },
  mounted() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 13) {
        this.getLink()
        event.preventDefault();
      }
    })
  },
  methods: {
    getLink() {
      if (this.email === '') {
        alert('please enter a valid email address')
        return
      }

      // EXTEND to allow fow per-distro access and general account access 

      requests.getAccountAccessLink(this.email).then(response => response.json())
        .then((body) => {
          this.postSubmitMessage = body.message
        })
    }
  }
}

</script>

<style type="text/css" media="screen">

</style>
