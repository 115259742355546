import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Cloud from '../views/Cloud.vue'
import Account from '../views/Account.vue'
import Analytics from '../components/Analytics.vue'
import Authenticate from '../views/Authenticate.vue'
import NotFound from '../views/NotFound.vue';

// for development
import PostDeploy from '../components/PostDeploy.vue';

//TODO change for if not logged in vs not -- check cookie/localstorage, etc.

const unAuthenticated = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/file',
    name: 'File',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/File.vue'),
    props(route) {
      return {  filename: route.query.filename }
    }
  },
  {
    path: '/get-access',
    name: 'Authenticate',
    component: Authenticate,
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: NotFound
  }
]

const authenticated = [
  {
    path: '/', 
    name: 'Home', 
    component: Home
  },
  {
    path: '/about', 
    name: 'About', 
    component: About
  },
  {
    path: '/get-access',
    name: 'Authenticate',
    component: Authenticate,
  },
  {
    path: '/deploys/:deploy_link', 
    name: 'Cloud',
    component: Cloud,
  },
  {
    path: '/account/:email',
    name: 'Account',
    component: Account,
  },
  {
    path: '/file',
    name: 'File',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/File.vue'),
    props(route) {
      return {  filename: route.query.filename }
    }
  },
  {
    path: '/success',
    name: 'Extended',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Extended.vue'),
    props(route) {
      return {  id: route.query.succeeded }
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: NotFound
  }
]

let routes = authenticated;
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
})

export default router
