<template>
  <div class="h-full w-full sm:px-8 md:px-16 sm:py-8">
  <template v-if="files.length == 0">
    <!--drag n drop zone-->
    <main class="mx-auto mt-10 max-w-screen-sm h-half box adv-upload rounded-lg"  :class="beingDragged ? 'is-dragover' : ''" id="drop_zone" @drop.prevent="dropHandler" @dragover.prevent="dragOverHandler" >
      <div class="text-lg">
        Upload Static Project
      </div>
      <div class="mb-8 text-xs">
        (HTML/CSS/JavaScript files)
      </div>
    <div class="flex w-full items-center justify-center bg-grey-lighter">
        <!--TODO: -- make upload button sameish size as Deploy button, vis a vis: https://wormhole.app/-->
      <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue  hover:bg-gray-100">
          <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <span class="text-base leading-normal">
            <div v-if="multiLevelDirs" >
             Directory
             <!--tool-tip: if your directory has directories (yo dawg) (like /dist after you built from dev directory)-->
            </div>
            <div v-else >
             File(s)
             <!--tool tip: files (one or more files that end in .html, .css, .js) / -->
            </div>
            </span>
            <input type='file' :webkitdirectory="multiLevelDirs" multiple  @change="handleFiles" :disabled="files.length > 0" class="hidden" id="hidden-files" />
      </label>
      <label
      for="toogleA"
      class="absolute mt-20 cursor-pointer"
      >
      <div class="relative">
        <input id="toogleA" type="checkbox" unchecked class="hidden" @change="multiLevelDirs = !multiLevelDirs" :disabled="files.length > 0" />
        <div
          class="toggle__line w-5 h-2 bg-gray-400 rounded-full shadow-inner"
        ></div>
        <div
          class="toggle__dot absolute w-4 h-4 bg-white rounded-full shadow inset-y-0 left-0 bg-gray-300 "
        ></div>
      </div>
      <div
        class=" text-gray-700 font-medium"
      >
      </div>
      </label>
    </div>
    <div class="mt-6">
      <template v-if="!update">
        <button @click="populateExampleFiles" class="underline">try an example </button>
      </template>
    </div>
    <div class="text-xs absolute bottom-3 left-4">
      <!--drag and drop files here, or select files/directory-->
    </div>
  </main>
  </template>
  </div>
  <div class="relative pr-10">
    <template v-if="files.length > 0">
      <div v-if="multiLevelDirs" class="relative pr-0">
        <button v-if="files.length != 0" class="absolute left-15 pr-10" >
          <div class="close icon" @click="updateRemovedFiles"></div>
        </button>
        <ul class="list-container pb-3 pl-10">
          <li class="list-none" v-for="file in files" :key="file.name">
            <!--<router-link class="underline" target="_blank" to="/file">{{ file.webkitRelativePath }}</router-link>-->
            <router-link class="underline" target="_blank" :to="{ path: '/file', query: { filename: file.name }}">{{ file.webkitRelativePath }}</router-link>
          </li>
        </ul>
      </div>
      <div v-else class="relative">
        <button v-if="files.length != 0" class="absolute left-15 pr-10">
          <div class="close icon" @click="updateRemovedFiles"></div>
        </button>
        <ul class="list-container pb-3 pl-10">
          <li class="list-none" v-for="file in files" :key="file.name">
            <router-link class="underline" target="_blank" :to="{ path: '/file', query: { filename: file.name }}">{{ file.name }}</router-link>
          </li>
        </ul>
      </div>
    </template>
  </div>

  <div class="sm:flex justify-center">
    <div class="pt-2">
      <button @click="uploadFiles" :disabled="loading" class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-300 rounded shadow">DEPLOY</button>
    </div>
    <!--Real-Time Analytics Toggle-->
                                          <!--idk this pattern feels weird for small vs large -->
    <!--<div class="flex items-center justify-center px-4 sm:pt-2 pt-6">-->
    <!--<label for="toggleB" class="flex items-center cursor-pointer">-->
      <!--<div class="relative">-->
        <!--<input type="checkbox" checked id="toggleB" class="sr-only" @change="realTimeAnalytics = !realTimeAnalytics"/>-->
        <!--<div class="block bg-gray-600 w-8 h-5 rounded-full"></div>-->
        <!--<div class="dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition"></div>-->
      <!--</div>-->
      <!--<div class="ml-3 text-gray-700 text-sm font-medium">-->
        <!--Real-Time Analytics<span id="extend" class="px-1 text-gray-400">?</span>: {{ realTimeAnalytics ? 'On' : 'Off' }}-->
      <!--</div>-->
    <!--</label>-->
    <!--</div>-->
  </div>

  <template v-if="loading">
    <div class="Absolute-Center z-50 justify-center items-center">
      <div class="animate-spin rounded-full h-32 w-32 border-b-2 border-green-600"></div>
    </div>
  </template>
  <Biller v-if="showSubscribeModal" :show="showSubscribeModal"/>
</template>

<script>
import Biller from '../Biller.vue'
import requests from '@/requests/requests.js'
import sample_project from '@/helpers/sample_project.js'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default {
  name: 'HelloWorld',
  components: {
    Biller,
  },
  props: {
    msg: String,
    subdomain: String,
    customDomain: String,
    update: Boolean,
    deploy_link: String
  },
  data: function() {
    return {
      files: [],
      multiLevelDirs: false,
      showSubscribeModal: false,
      loading: false,
      beingDragged: false,
      realTimeAnalytics: false
    };
  },
  emits: ["deployed", 'deployedLoggedIn'],
  mounted(){
    tippy('#extend', {
        content: 'Current active users, locations, duration, etc. This is in addition to server-side analytics which are always enabled.',
      });
  },
  computed: {
    hasStandardPlan() {
      return window.localStorage.getItem('spinupuserplan') === 'standard'
    },
    isLoggedIn() {
      return window.localStorage.getItem('spinupuseremail')
    }
  },
  methods: {
    handleFiles(event, isDragAndDrop=false) {
      if (isDragAndDrop) {
        for (var i = 0; i < event.dataTransfer.items.length; i++) {

          // this is a poor check to guess if user dropped a directory
          // see: https://stackoverflow.com/questions/25016442/how-to-distinguish-if-a-file-or-folder-is-being-dragged-prior-to-it-being-droppe/25095250
          if (!event.dataTransfer.items[i].type) {
            alert(`you can only drag and drop files (not whole folders/directories) \nif you want to drop a directory, toggle "files" to "directories" \nand select directory by clicking the center upload box
            \n make sure all files are html/css/js or assets`)
            return
          }

          var file = event.dataTransfer.items[i].getAsFile();
          this.files.push(file)
        }
      } else {
        let fileObjects = event.target.files
        this.files = fileObjects
      }
      this.emitFiles()
      this.files.forEach(fyle => this.persistToLocalStorage(fyle));
    },
    persistToLocalStorage(fileObj) {
      let file = fileObj
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onload = function() {
        let thing = reader.result
        window.localStorage.setItem(fileObj.name, thing)
      };
      reader.onerror = function() {
        console.log(reader.error);
      };
    },
    deleteAllFilesFromLocalStorage() {
      this.files.forEach(fyle => window.localStorage.removeItem(fyle.name));
    },
    uploadFiles() {
      //TODO: add generic deploys info for user maybe -- user.has_at_least_one_deploy = true etc. put login in one damn component
      //if (window.localStorage.getItem('spinupuserplan') === 'free' && this.deployments.length == 1)  {
        //this.showSubscribeModal = true
        //return
      //}

      if (this.files.length < 1) {
        alert('please select a file to deploy!')
        return
      }

      if (this.subdomain.length < 1 && this.customDomain.length < 1) {
        alert('subdomain/domain cannot be empty')
        return
      }

      if (this.files != [] && this.update) {
        this.loading = true
        requests.postFiles(this.files, { subdomain: this.subdomain, custom_domain: this.customDomain, deploy_link: this.deploy_link}, this.multiLevelDirs, 'update').then((response) => response.json())
          .then((body) => {
            this.loading = false
            this.deleteAllFilesFromLocalStorage()
            if (this.isLoggedIn) {
              this.$emit('deployedLoggedIn', body)
            } else {
              this.$emit('deployed', body)
            }
          })
          .catch((err) => {
            this.loading = false
            alert(err)
          })
      } else if (this.files != []) {
        this.loading = true
        requests.postFiles(this.files, { subdomain: this.subdomain, custom_domain: this.customDomain }, this.multiLevelDirs).then((response) => response.json())
          .then((body) => {
            this.loading = false
            //if (this.subdomain != body.subdomain) { return }
            this.deleteAllFilesFromLocalStorage()
            if (this.isLoggedIn) {
              this.$emit('deployedLoggedIn', body)
            } else {
              this.$emit('deployed', body)
            }
          })
          .catch((err) => {
            this.loading = false
            console.log(err)
            alert('Oh no! something went wrong!')
          })
      } else {
        console.log('error')
        this.loading = false
      }
    },
    emitFiles () {
      this.$bus.trigger('filesChanged', this.files)
    },
    updateRemovedFiles() {
      this.files = []
      this.emitFiles()
      this.deleteAllFilesFromLocalStorage()
      this.multiLevelDirs = false
    },
    populateExampleFiles() {
      let html = sample_project.html()
      let styles = sample_project.styles()
      let scripts = sample_project.scripts()

      let file1 = new File([html], "index.html", {
        type: "text/html",
      });
      let file2 = new File([styles], "styles.css", {
        type: "text/css",
      });
      let file3 = new File([scripts], "scripts.js", {
        type: "application/javascript",
      });

      this.files = [file1, file2, file3]
      this.emitFiles()
      this.files.forEach(fyle => this.persistToLocalStorage(fyle));
    },
    dropHandler(event) {
      this.beingDragged = false
      this.handleFiles(event, true)
    },
    dragOverHandler(event) {
      this.beingDragged = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}

.toggle__dot {
  top: -.25rem;
  left: -.25rem;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
}

/* live analytics toggle */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

.close.icon {
  color: #000;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 21px;
  height: 21px;
}

.close.icon:before {
  content: '';
  position: absolute;
  top: 10%;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.close.icon:after {
  content: '';
  position: absolute;
  top: 10%;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.list-container {
  display: inline-block;
  text-align:left;
}

.Absolute-Center {
  margin: auto;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 767px) {
  .box {
    font-size: 1rem;
    background-color: #ededed;
    position: relative;
    padding: 100px 20px;
  }

  .box.is-dragover {
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
  }

  .box.adv-upload {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
  }
}

</style>
