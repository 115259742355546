<template>
  <div v-if="!isLoggedIn">
    <EmailSignup :deploy_link="deploy_link"/>
  </div>
<div class="flex flex-col">
<div class=" sm:flex mx-2 mb-12 mt-14 flex-grow justify-center">
  <section class="flex flex-col">
  <div>
   <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
     <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
     <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
   </svg>
  </div>
  <div class="text-3xl ">
    <a class="hover:underline text-blue-600" @click="reloadChildComponent" :href="'https://' + deploy_link" target="_blank">{{deploy_link}}</a> is live!
  </div>
  </section>
<div v-if="!dns_resolved" >
  <div  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-6xl">
      <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <header class="text-yellow-600 pt-5">
        PENDING
      </header>
        <div class="loader mx-auto ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        <div class="items-start p-1 border-b border-solid border-blueGray-200 rounded-t">
        </div>
          <div class="relative flex-auto">
            <DnsRecord :postDeploy=true ></DnsRecord>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</div>
  <Cloud :deploy_link="deploy_link" postDeploy=true :key="componentKey" :deployedAt="deployed_at" />
</div>
</template>
<script>

import DnsRecord from '../components/cloud_page/DnsRecord.vue';
import EmailSignup from '../components/post_deploy/EmailSignup.vue';
import Cloud from '../views/Cloud.vue';
import requests from '@/requests/requests.js'

export default {
  components: {
    DnsRecord,
    Cloud,
    EmailSignup
  },
  props: {
    deploy_link: String, 
    cloud_link: String,
    customDomain: String,
    deployed_at: String
  },
  data: function () {
    return {
      dns_resolved: true,
      subdomain: '',
      componentKey: 0
    }
  },
  computed: {
    isLoggedIn() {
      let login = localStorage.getItem('spinupuser')
      return login
    }
  },
  created() {
    if (this.customDomain && this.customDomain.length > 0) { 
      this.dns_resolved = false
      this.pingDns() 
    }
  },
  methods: {
    pingDns() {
      this.interval = setInterval(() => {
        requests.resolved(this.customDomain).then(response => response.json())
        .then((body) => {
          if (body.is_resolved) {
            this.dns_resolved = true
            this.clearTimer = true
          } else {
            this.dns_resolved = false
          }
        })
        if (this.clearTimer == true) { clearInterval(this.interval); }
      }, 1500); 
    },
    reloadChildComponent() {
      setTimeout(() => { this.componentKey += 1 }, 2000);
    }
  },
}

</script>

<style type="text/css" media="screen">

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #28a745;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px ;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #28a745;
  }
}

.list-container {
  display: inline-block;
  text-align:left;
}

ul {
  /* for IE below version 7 use `width` instead of `max-width` */
}

</style>
