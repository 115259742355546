<template>
  <div class="relative p-4 bg-white rounded shadow-xl stats-item flex flex-col mt-6 w-full dark:bg-gray-825">
    <div class="flex flex-col flex-grow">
      <h3 class="font-bold dark:text-gray-100">Referring Site</h3> 
      <div class="flex flex-col flex-grow fade-enter-active">
        <bar-chart :colors="['#ff9900']" :data="serialized_referral_sites" ></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: function() {
  },
  computed: {
    serialized_referral_sites() {
      if (!this.referrals || !this.referrals.data) { return [] }
      return this.referrals.data.map(ref => [ref.data, ref.hits.count])
    }
  },
  props: {
    referrals: Object
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
