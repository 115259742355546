<template>
<div class="flex">
  <div v-if="!postDeploy">
    <Login />
  </div>
</div>
<div v-if="loaded" class="flex flex-col card bg-gray-50 dark:bg-gray-900">
  <div class="sticky top-0 sm:py-3 py-2 z-10 fullwidth-shadow bg-gray-50 dark:bg-gray-850">
    <div class="items-center w-full flex">
      <div class="flex items-center w-full" >
        <label class="block text-left relative inline-block text-left mr-auto" @mouseover="shakeEmailContainer" :id="postDeploy ? 'verify' : ''" style="max-width: 200px;">

          <select class="form-select block w-full ml-2 mt-1 font-bold text-lg" :class="postDeploy ? 'disabled' : ''" @change="onChange($event)" >
              <option selected >{{deploy_link || deployLink}}</option>
              <option v-for="option in otherDeployments" :value="option" :key="option">{{option}}</option>
              <option>+ new deploy</option>
          </select>
        </label>
        <div class="block text-xs lg:text-sm font-bold text-gray-500 dark:text-gray-300" @mouseover="shakeEmailContainer" :id="postDeploy ? 'verify' : ''" >
          <a class="underline" :href="'https://' + deployLink" :class="postDeploy ? 'disabled' : ''" target="_blank" @click="reloadChildComponent">Latest Deploy: {{ formattedDateTime() }} UTC</a>
        </div>
        <!--<div class="md:relative ml-10 mx-auto" @mouseover="shakeEmailContainer" :id="postDeploy ? 'verify' : ''" >-->
          <!--<div id="update" style="" class="px-1 border-2 rounded-md"><button :class="postDeploy ? 'disabled' : ''" class="outline-none focus:outline-none font-bold">deploy history</button> </div>-->
        <!--</div>-->
        <div class="md:relative ml-10 mx-auto" @mouseover="shakeEmailContainer" :id="postDeploy ? 'verify' : ''" >
          <div id="update" style="" class="px-1 border-2 rounded-md"><button :class="postDeploy ? 'disabled' : ''" class="outline-none focus:outline-none font-bold" @click="toggleUpdateModal()">update</button> </div>
        </div>
        <div class="md:relative mx-auto " @mouseover="shakeEmailContainer" :id="postDeploy ? 'verify' : ''" >
          <td id='undeploy' style="" class="px-1"><button :class="postDeploy ? 'disabled' : ''" class=" bg-red-400 font-bold rounded-md outline-none focus:outline-none" @click="unDeploy"><div class="px-1">destroy</div></button></td>
        </div>
      </div>
    </div>
  </div>

           <!--man this is dumb-->
  <div v-if="deploy_link || deployLink">
    <Analytics :deploy_link="deploy_link || deployLink" :key="componentKey"/>
  </div>
<div>
    <div v-if="showUpdateModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
          <div class="items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
            <button class="p-1 ml-auto border-0 text-black opacity-3 float-left text-3xl leading-none font-semibold outline-none  focus:outline-none" v-on:click="toggleUpdateModal()">
              <span class="h-6 w-6 text-xl block outline-none focus:outline-none">
                x
              </span>
            </button>
          </div>
          <div v-if="!customDomain" class="relative p-6 flex-auto">
            <FileUploader :deployLink="deployLink" :subdomain="subdomain" :customDomain="customDomain" update='true' @deployed="toggleUpdateModal(); setDeployInfo($event)" @deployedLoggedIn="toggleUpdateModal(); setDeployInfo($event)"/>
          </div>
          <!--this is because as of 1/8/23 the ssl cert gets deleted on update, which should not happen-->
          <div v-else class="relative p-6 flex-auto">
            Because this is a custom domain, please delete this distribution and deploy it again in order to update!
          </div>
        </div>
      </div>
    </div>
    <div v-if="showUpdateModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>

<Biller v-if="showSubscribeModal" :show="showSubscribeModal"/>
</div>

<div v-if="!dns_resolved" >
  <div  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-6xl">
      <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <header class="text-yellow-600 pt-5">
        PENDING
      </header>
        <div class="loader mx-auto ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        <div class="items-start p-1 border-b border-solid border-blueGray-200 rounded-t">
        </div>
          <div class="relative flex-auto">
            <DnsRecord></DnsRecord>
          </div>
      </div>
    </div>
  </div>
  <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</div>
</template>

<script >
import FileUploader from '../components/FileUploader.vue'
import Biller from '../components/Biller.vue'
import Analytics from '../components/Analytics.vue'
//import DeploymentControl from '../components/cloud_page/DeploymentControl.vue'
import requests from '@/requests/requests.js'
import router from '@/router/index.js'
import Login from '../components/auth/Login.vue'
import DnsRecord from '../components/cloud_page/DnsRecord.vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default {
  name: 'Cloud',
  components: {
    FileUploader,
    Biller,
    DnsRecord,
    Analytics,
    Login,
    //DeploymentControl
  },
  data() {
    return {
      showUpdateModal: false,
      showSubscribeModal: false,
      copied: false,
      timeRemaining: '5 days 00 hrs 00 mins 00 secs',
      expires_at: '',
      deployed_at: '',
      deployLink: '',
      subdomain: '',
      customDomain: '',
      domain_type: '',
      loaded: true,
      dns_resolved: true,
      componentKey: 0,
      otherDeployments: []
    }
  },
  props: {
    postDeploy: Boolean,
    deploy_link: String,
    deployedAt: String
  },
  //beforeRouteEnter(to, from, next) {
  //},
  created(){
   if (this.postDeploy) {return}

   const urlSearchParams = new URLSearchParams(window.location.search);
   const q_params = Object.fromEntries(urlSearchParams.entries());

    let distribution = this.$route.params.deploy_link;
    requests.getCloudInfo(q_params.code, distribution).then(response => {
      if (!response.ok) throw Error(response.status) // Fetch is dumb AF, and this is how you pass to the catch block
      return response.json() }
    )
     .then((body) => {
       this.loaded = true
       this.setDeployInfo(body)

       this.toggleLogin('login')
       if (this.customDomain && this.customDomain.length > 0) {
         this.pingDns()
       }
     })
     .catch(error => {
       if (error) {
         if (error == '401') {
           this.toggleLogin('logout')
           router.push({ path: 'get-access' })
         }
         alert('Oh no! something went wrong!')
       }
     })
    this.cleanURL()
  },
  mounted(){
    if (this.postDeploy) {
      tippy('#verify', {
          content: 'must confirm your email',
        });
    }
  },
  computed: {
    command() {
      return `spinup auth --token=${this.token}`
    },
  },
  methods: {
    showButtons() {
    },
    hideButtons() {
    },
    openUploadModal() {
    },
    toggleUpdateModal() {
      this.showUpdateModal = !this.showUpdateModal;
    },
    setDeployInfo(deploy_info) {
      this.deployed_at = (new Date(deploy_info.deployed_at)).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
      this.expires_at = (new Date(deploy_info.expires_at)).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
      this.deployLink = deploy_info.deploy_link
      this.domainType = deploy_info.domain_type
      this.subdomain = deploy_info.subdomain
      this.customDomain = deploy_info.custom_domain
      this.user = deploy_info.user
      this.email = deploy_info.user.email
      this.otherDeployments = deploy_info.other_deployments
      //this.otherDeployments = ['deploy1', 'deploy2', 'deploy3']
    },
    //TODO: IMPLEMENT LATER
    timer() {
      var end = new Date(this.expires_at);

      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;
      var timer;

      let showRemaining = () => {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {

            clearInterval(timer);
            this.timeRemaining = 'EXPIRED!';

            return;
        }
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        var remaining = days + ' days ';
        remaining += hours + ' hrs ';
        remaining += minutes + ' mins ';
        remaining += seconds + ' secs';
        this.timeRemaining = remaining
      }
      timer = setInterval(showRemaining, 1000);
    },
    unDeploy(){
      let answer = window.confirm('Are you sure? This will delete this deployment.')
      if (answer) {
        requests.destroy(this.deployLink).then((response) => response.json())
        .then((body) => {
          window.location = '/'
        })
      }
    },
    copyCommand() {
      let elementText = this.command
      navigator.clipboard.writeText(elementText);
      this.copied = true
    },
    cleanURL() {
      window.history.pushState({}, document.title, `/deploys/${this.$route.params.deploy_link}`);
    },
    pingDns() {
      this.interval = setInterval(() => {
        requests.authd_resolved(this.customDomain).then(response => response.json())
        .then((body) => {
          if (body.is_resolved) {
            this.dns_resolved = true
            this.clearTimer = true
          } else {
            this.dns_resolved = false
          }
        })
        if (this.clearTimer == true) { clearInterval(this.interval); }
      }, 1500);
    },
    reloadChildComponent() {
      setTimeout(() => { this.componentKey += 1 }, 2000);
    },
    shakeEmailContainer() {
      return false
      //if (!this.postDeploy) { return }
      //this.$bus.trigger('hoverOnDisabled', 'shake')
    },
    onChange(event) {
      if (event.target.value === "+ new deploy") {
        if (window.localStorage.getItem('spinupuserplan') === 'free')  {
          this.showSubscribeModal = true
          return
        }
        window.location.href = "/"
      } else {
        //router.push({ name: 'Cloud', params: { deploy_link: event.target.value }})
        //router.go({ name: 'Cloud', params: { deploy_link: event.target.value }})
        // TODO this sucks though
        this.loaded = false
        //this.$router.push({ name: 'Cloud', params: { deploy_link: event.target.value }})
        window.location.href = `/deploys/${event.target.value}`
      }
    },
    formattedDateTime(){
      let datetimeString = this.deployedAt || this.deployed_at
      return (new Date(datetimeString)).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
    },
    // move to some kind of Auth Mixin or something
    toggleLogin(action){
      switch (action) {
        case 'login':
          window.localStorage.setItem('spinupuseremail', this.email)
          window.localStorage.setItem('spinupuserplan', this.user.plan)
          this.$bus.trigger('authchange', '')
          break;
        case 'logout':
          window.localStorage.removeItem('spinupuseremail')
          window.localStorage.removeItem('spinupuserplan')
          this.$bus.trigger('authChange', '')
          window.location.href = '/'
          break;
        default:
      }
    }
  }
}

</script>

<style>

.card {
    display: flex;
    margin: 0 0%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
  }

@media (min-width:768px)  {
  .card {
      display: flex;
      margin: 0 25%;
      padding: 1.5rem;
      border-radius: 0.5rem;
      background-color: #fff;
    }
}


.code-block {
  background-color: #f7f7f7;
  line-height: 1.45em;
  font-size: 13px;
  margin-bottom: 1.1em;
  height: 40px;
  width: 365px;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.8em 1em;
  color: #1a1a1a;
  overflow: auto;
  display:inline-block;
  border-radius: 0.5rem;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}

code {
  border-radius: 0.05rem;
  font-family: Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace;
  background-color: #f7f7f7;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}

.cloud-table {
  white-space: nowrap
}

.disabled {
  pointer-events: none;
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1s linear infinite;
	animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

</style>
