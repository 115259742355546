<template>
    <div class="relative p-4 bg-white rounded shadow-xl stats-item flex flex-col mt-6 w-full dark:bg-gray-825">
      <div class="flex flex-col flex-grow">
        <h3 class="font-bold dark:text-gray-100">Country</h3> 
        <div class="">
          <column-chart :colors="['#990099']" :data="serialized_countries"></column-chart>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  data: function() {
    return {
      data: [
        ['United States', 3],
        ['Canada', 1],
      ]
    }
  },
  props: {
    geoData: Array
  },
  computed: {
    serialized_countries() {
      return this.serialize()
    }
  },
  methods: {
    serialize() {
      if (!this.geoData || !this.geoData.data) { return [] }
      let items = this.geoData.data.map(continent_list => continent_list.items)
      let geoItems = Array.prototype.concat.apply([], items)
      return geoItems.map(item => [item.data, item.visitors.count])
    }
  }
};
</script>

<style scoped>

</style>
