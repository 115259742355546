import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import $bus from './helpers/EventBus.js'
import router from './router'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'


//can enable to do event level analytics, otherwise included script is included in public/index.html
//import SimpleAnalytics from "simple-analytics-vue";
//createApp.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== "production" });

const app = createApp(App).use(router)
app.mount('#app')
app.config.globalProperties.$bus = $bus
app.use(VueChartkick)

/**
 * Add the warning message in console directed at devs who are looking there
 */
function domContentLoaded() {
  "use strict";

  setTimeout(console.log.bind(console, "\n%cHi!", "color:green;font-size:40px;"));
  setTimeout(console.log.bind(console, "If you want to chat about anything that went into building this project, feel free to reach out %ctwitter.com/HeySpinUp","color:green;",""));
}
// could be fun for later: https://stackoverflow.com/questions/29681775/how-to-create-hyperlinks-linked-to-javascript-functions-in-chromes-console-log#:~:text=The%20Google%20Chrome%20console%2C%20like,actually%20log%20%22custom%20URLs%22
document.addEventListener("DOMContentLoaded", domContentLoaded);
