<template>
  <div class="h-full w-full sm:px-8 md:px-16 sm:py-8">
    <main class="container mx-auto mt-14 max-w-screen-sm h-half ">
      <div class="mb-10 text-lg">
        From the command line
      </div>
      <p class="pt-5">
        <code id="curl-create" class="curl-block text-base text-left relative">
        <button @click="copyCommand" class="absolute top-0 right-0 px-5 py-2 outline-none" style="outline:none !important;" >
          <i class="fa fa-copy outline-none" style=";font-size:12px">
            <span v-if="copied" class="fade"> copied </span>
          </i>
        </button>
        <div class="pr-6">
$ {{request}}
        </div>
        </code>
      </p>
      <div class="mt-12">
             <!--make this animated like the type js or https://railway.app/-->
             <!--or, <code class="cli-block">spinup</code> with <a href="https://npmjs.com" target="_blank" class="underline">the cli</a>-->
        <!--- - instantly install githooks to deploy on every commit or push (script for this on post-signup) -->
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      greeting: "",
      files: [],
      copied: false
    };
  },
  props: {
    subdomain: String,
    customDomain: String
  },
  computed: {
    request() {
      if (this.customDomain) {
        if (this.files.length < 1) {
          return `curl https://spinup.dev/create -F "custom_domain=${this.customDomain}" -F "index.html=@path/to/index.html"`
        } else {
          return `curl https://spinup.dev/create -F "custom_domain=${this.customDomain}"` + this.generateFilesFieldsString()
        }
      } else {
        if (this.files.length < 1) {
          return `curl https://spinup.dev/create -F "subdomain=${this.subdomain}" -F "index.html=@path/to/index.html"`
        } else {
          return `curl https://spinup.dev/create -F "subdomain=${this.subdomain}"` + this.generateFilesFieldsString()
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$bus.on('filesChanged', (data) => {
        this.files = data
      })
    })
  },
  methods: {
    copyCommand() {
      let elementText = this.request
      navigator.clipboard.writeText(elementText);
      this.copied = true
    },
    generateFilesFieldsString() {
      let filesString = ''

      if (this.files[0].webkitRelativePath) {
        this.files.forEach(function(file, index) {
          filesString += ` -F "${file.webkitRelativePath}=@${file.webkitRelativePath}"`
        })
        return filesString
      } else {
        this.files.forEach(function(file, index) {
          filesString += ` -F "${file.name}=@${file.name}"`
        })
        return filesString
      }

    }
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

.curl-block {
  background-color: #f7f7f7;
  line-height: 1.45em;
  font-size: 13px;
  margin-bottom: 1.1em;
  height: 100%;
  width: 75%;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.8em 1em;
  color: #1a1a1a;
  overflow: auto;
  display:inline-block;
  border-radius: 0.5rem;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}

.cli-block {
  background-color: #f7f7f7;
  font-size: 13px;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  color: #1a1a1a;
  display:inline-block;
  border-radius: 0.3rem;
}

code {
  border-radius: 0.05rem;
  font-family: Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace;
  background-color: #f7f7f7;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}
</style>
