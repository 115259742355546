<template>
    <div class="relative p-4 bg-white rounded shadow-xl stats-item flex flex-col mt-6 w-full dark:bg-gray-825">
      <div class="flex flex-col flex-grow">
        <h3 class="font-bold dark:text-gray-100">Device/Operating System</h3> 
        <div class="flex flex-col flex-grow fade-enter-active">
          <pie-chart  :colors="colors" :data="serialized_devices"></pie-chart>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  data: function() {
    return {
      colors: ['#dc3912', '#109618', '#3366cc', '#990099', '#ff9900']
    }
  },
  props: {
    devices: Object
  },
  computed: {
    serialized_devices() {
      if (!this.devices || !this.devices.data) { return [] }
      return this.devices.data.map(ref => [ref.data, ref.visitors.count])
    }
  },
};
</script>

<style scoped>

</style>
