<template>
    <div class="relative p-4 bg-white rounded shadow-xl stats-item flex flex-col mt-6 w-full dark:bg-gray-825">
      <div class="flex flex-col flex-grow">
        <h3 class="font-bold dark:text-gray-100">Browser</h3> 
        <div class="flex flex-col flex-grow fade-enter-active">
          <pie-chart :colors="colors" :donut="true" :data="serialized_browsers"></pie-chart>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  data: function() {
    return {
      colors: [
    "#3366cc",
    "#ff9900",
    "#990099",
    "#109618",
    "#dc3912"
    ]
    }
  },
  props: {
    browsers: Object
  },
  computed: {
    serialized_browsers() {
      if (!this.browsers || !this.browsers.data) { return [] }
      return this.browsers.data.map(ref => [ref.data, ref.visitors.count])
    }
  },
};
</script>

<style scoped>

</style>
