<template>
  <div class="h-full w-full sm:px-8 md:px-16 sm:py-8">
    <main class="container mx-auto mt-14 max-w-screen-sm h-half ">
      <div class="mb-10">
        to delete
      </div>
      <p class="pt-5 relative">
        <button @click="copyCommand" class="absolute top-0 right-0 px-5 py-2 outline-none" style="outline:none !important;" ><i class="fa fa-copy outline-none" style=";font-size:12px"></i></button>
        <code  class="text-base text-left ">
         <pre id="curl-create" class="text-left">

         $ curl -X DELETE http://{{subdomain}}.spinup.cloud -H "token: 12343456"
         </pre>
        </code>
      </p>
    </main>
    <main class="container mx-auto mt-14 max-w-screen-sm h-half ">
      <!--and update-->
    </main>
  </div>
</template>

<script>
//(move to Right of Uopload Container, auto populate with full path file upload, and transition to show update/delete after link generation) curl examples for 1) initial, and, post create, 2) update and 3) destroy
export default {
  props: {
    subdomain: String,
    deployLink: String
  },
  data: function() {
    return {
      greeting: ""
    };
  }
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}

pre {
  background-color: #f7f7f7;
  line-height: 1.45em;
  font-size: 13px;
  margin-bottom: 1.1em;
  padding: 0.8em 1em;
  color: #1a1a1a;
  overflow: auto;
}

code {
  font-family: Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace;
}
</style>
