export default {
  html(){
    return `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">

        <title>Deployed on Spin Up!</title>
        <!--<link rel="icon" href="favicon.ico" type="image/png" />-->
        <link href="https://fonts.googleapis.com/css?family=Reem+Kufi|Roboto:300" rel="stylesheet">
        <link href="https://use.fontawesome.com/releases/v5.12.0/css/all.css" rel="stylesheet">
        <link rel="stylesheet" href="styles.css">
      </head>
      <body>
        <main>
          <div class="intro" id="intro">Live Example hosted at </div>
          <div class="tagline">This a live example site hosted on <a href="https://spinup.cloud" target="_blank">Spin Up</a></div>
          <!-- Find your icons from here - https://fontawesome.com/icons?d=gallery&s=brands -->
          <div class="icons-social">
            <a target="_blank" href="https://twitter.com/HeySpinUp"><i class="fab fa-twitter"></i></a>
          </div>
        </main>
      </body>
      <script src="scripts.js"></script>
      </html>
    `
  },
  scripts(){
    let js = 'let intro = document.getElementById("intro")\n' +
             "console.log('That is right my friend - this page has working JS!')\n" +
             'intro.innerHTML += `<a href="https://${window.location.host}" target="_blank">${window.location.host}</a>`\n'
    return js
  },
  styles(){
    return `       html {
              font-family: 'Roboto', sans-serif;
            }

            @media (min-width: 576px) {
              html {
                font-size: 14px;
              }
            }

            @media (min-width: 768px) {
              html {
                font-size: 16px;
              }
            }

            @media (min-width: 992px) {
              html {
                font-size: 18px;
              }
            }

            @media (min-width: 1200px) {
              html {
                font-size: 20px;
              }
            }

            .icons-social i {
              font-size: 2em;
            }

            main {
              display: flex;
              flex-direction: column;
              min-height: 100vh;
              justify-content: center;
              padding: 0 30px;
              text-align: center;
            }

            main > .intro {
              font-family: 'Arial', sans-serif;
              font-size: 1.75em;
              font-weight: 600;
            }

            main > .tagline {
              font-size: 1.5rem;
              margin: 1.5rem 0;
              font-weight: 100;
            }

            .icons-social i {
              padding: 10px;
            }

            .devto {
              margin-bottom: -0.20rem;
            }

            .devto svg {
              margin-bottom: -0.20rem;
              margin-left: 0.675rem;;
              width: 2.65rem;
              height: 2.65rem;
            }

            main {
              background: #FAFAFA;
              color: #28a745;
            }

            .icons-social a {
              color: #28a745;
            }

            .icons-social a svg path{
              fill: #28a745;
            }

            main {
              background: #FAFAFA;
              color: #28a745;
            }

            .icons-social a {
              color: #1da1f2;

            }

            a {
              color: #28a745;
            }

            .icons-social a svg path{
              fill: ##1da1f2;
            }`
  }
}
