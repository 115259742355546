<!--(smaller comps: Countries, Devices, Browser, Referral Source (website name))-->
<template>
  <div class="">
    <div class="graph-inner">
      <div class="flex flex-wrap">
        <!--<div class="px-8 w-1/2 my-4 lg:w-auto  border-r lg:border-r-0">-->
          <!--<div class="flex block ml-1 md:ml-2 mr-auto text-xs md:text-sm font-bold text-gray-500 dark:text-gray-300">-->
            <!--<div class="">-->
              <!--{{live_users}} Live Users -->
            <!--</div>-->
            <!--<svg class="inline w-2 mr-1 md:mr-2 text-green-500 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8"></circle></svg>-->
          <!--</div>-->
        <!--</div>-->
        <div class="px-8 w-1/2 my-4 lg:w-auto lg:border-l border-gray-300">
          <div class="text-xs font-bold tracking-wide text-gray-500 uppercase dark:text-gray-400 whitespace-nowrap">
            Visitors (Unique)
          </div>
            <b class="mr-4 text-xl md:text-2xl dark:text-gray-100">
              {{uniq_visitors}}
            </b>
        </div>
        <div class="px-8 w-1/2 my-4 lg:w-auto lg:border-l border-gray-300">
          <div class="text-xs font-bold tracking-wide text-gray-500 uppercase dark:text-gray-400 whitespace-nowrap">
            Total Page Views
          </div>
            <b class="mr-4 text-xl md:text-2xl dark:text-gray-100">
              {{total_views || "--" }}
            </b>
        </div>
        <!--<div class="px-8 w-1/2 my-4 lg:w-auto lg:border-l border-gray-300">-->
          <!--<div class="text-xs font-bold tracking-wide text-gray-500 uppercase dark:text-gray-400 whitespace-nowrap">-->
            <!--Avg visit length-->
          <!--</div>-->
            <!--<b class="mr-4 text-xl md:text-2xl dark:text-gray-100">-->
              <!--{{avg_visit_length || "--" }}-->
            <!--</b>-->
        <!--</div>-->
      </div>
      <div class="relative px-2 pt-6">
        <line-chart :colors="['#3366cc']" :data="serialized_visitors" label="Visitors" empty="Waiting for a site visitor..." :library='{ticks: {precision:0}}'></line-chart>
      </div>
    </div>
  </div>
</template>

<script>
import requests from '@/requests/requests.js'

export default {
  components: {
  },
  data: function() {
    return {
      live_users: 0
    } 
  },
  props: {
    visitors: Object, 
    general: Object,
    avg_visit_length: Number,
    initial_live_users: Number, 
    requests: Object,
    deploy_link: String
  },
  mounted() {
    this.live_users = this.initial_live_users
  },
  created() {
    setInterval(() => {
      requests.pingActiveUsers(this.deploy_link).then(response => response.json())
      .then((body) => {
        this.live_users = body.live_visitors
      })
    }, 5000); 
  },
  computed:  {
    serialized_visitors() {
      return this.serialize_visitors()
    },
    uniq_visitors() {
      return this.general.unique_visitors 
    }, 
    total_views(){
      if (this.requests && this.requests.data.length > 0) { 
        return this.requests.data[0].hits.count
      }
      return null 
    }
  },
  methods: {
    serialize_visitors() {
      if (!this.visitors) { return {} }
      let serialized = new Object
      this.visitors.data.forEach(obj => serialized[obj.data] = obj.visitors.count)
      return serialized
    }
  }
};


</script>


<style scoped>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }

.graph-inner {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

</style>
