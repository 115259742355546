<template id="">
  <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-6xl">
      <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
        <div class="items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
        </div>
        <div class="relative p-6 flex-auto">
          <section
            class="flex flex-col lg:flex-row items-start items-center lg:justify-center w-full w-full lg:px-10 py-12 "
          >
            <article
              class="w-4/5 lg:w-custom mb-10 lg:px-4 py-10 text-center text-primary-dark bg-primary-white"
            >
              <h5 class="font-bold text-base">Standard Plan</h5>
              <h2 class="pb-4 flex justify-center font-bold border-b border-gray-300">
                <span class="text-6xl mr-1">$</span
                ><span class="text-6xl">3</span>
                <span class="text-xl font-thin mt-7">/mo</span>
              </h2>
              <ul class="text-sm font-bold">
                <li class="pt-4 pb-4 border-b border-gray-300">Unlimited Deployments</li>
                <li class="pt-3 pb-4 border-b border-gray-300">Unlimited Analytics</li>
                <li class="pt-4 pb-4 border-b border-gray-300">Unlimited https-enabled domains</li>
              </ul>
              <button v-if="hideSubscribe"></button>
              <button
                v-else
                @click="stripeCheckout"
                class="uppercase text-md mt-6 py-2 font-bold text-primary-very-light rounded-md"
              >
              Upgrade
              </button>
            </article>
          </section>
          <a :href="here" class=" text-xs underline">maybe later</a>
          <div class="text-xs">(one deployment is always free)</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script charset="utf-8">
import requests from '@/requests/requests.js'
export default {
  name: 'Biller',
  components: {
  },
  data() {
    return {
      showModal: false
    }
  },
  props: {
    userEmail: String,
    show: Boolean,
    hideSubscribe: Boolean
  },
  created() {
    this.showModal = this.show
  },
  computed: {
    here() {
      return window.location.href
    }
  },
  methods: {
    stripeCheckout() {
      requests.postCheckout('standard').then((response) => response.json()).then((body) => {
        let link = body.stripe_checkout
        window.location.replace(link)
      })
    },
  }
}

</script>

<style>

</style>
