<template>
  <div class="sm:flex">
    <div v-if="user" class="min-w-1/4 max-h-96">
      <UserProfile @upgrade="showSubscribeModal = true" :user="user" />
    </div>
    <!--<div v-else class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>-->

    <!--TODO (after paid users): make overflow y scroll -->
    <div v-if="deployments" class="flex flex-col">
    <div class="text-4xl font-bold underline px-2 sm:px-6 md:px-2 py-4 my-6">
      Deployments
    </div>
      <DeploymentRow v-for="deployment in deployments" :deployment="deployment" :key="deployment"/>
      <div class="pb-4">
        <button class="px-1 border-2 rounded-md-2" @click="open">new +</button>
      </div>
    </div>
    <!--<div v-else class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>-->
  </div>
  <PaymentSucceededModal v-if="showPaymentSucceededModal" :show="showPaymentSucceededModal" />
  <PaymentFailedModal v-if="showPaymentFailedModal" :show="showPaymentFailedModal" />
  <Biller v-if="showSubscribeModal" :show="showSubscribeModal"/>
</template>

<script>
import DeploymentRow from '../components/account/DeploymentRow.vue';
import UserProfile from '../components/account/UserProfile.vue';
import Biller from '../components/Biller.vue'
import PaymentSucceededModal from '../components/modals/PaymentSucceededModal.vue'
import PaymentFailedModal from '../components/modals/PaymentFailedModal.vue'
import requests from '@/requests/requests.js'
import router from '@/router/index.js'

export default {
  components: {
    DeploymentRow,
    UserProfile,
    Biller,
    PaymentSucceededModal,
    PaymentFailedModal,
  },
  props: {
  },
  //beforeRouteEnter(to, from, next) {
    //next()
  //},
  data: function() {
    return {
      user: null,
      deployments: null,
      showSubscribeModal: false,
      showPaymentSucceededModal: false,
      showPaymentFailedModal: false,
      email: null
    };
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const q_params = Object.fromEntries(urlSearchParams.entries());

    if ( Object.keys(q_params).includes('failed') ) { this.showPaymentFailedModal = true; return }
    if ( Object.keys(q_params).includes('succeeded') ) { this.showPaymentSucceededModal = true; return }

    this.email = this.$route.params.email

    if (!this.email) {
      router.push({ name: 'Not Found', params: { catchAll: 'notfound' } })
    }

    requests.getUser(q_params.code, this.email).then(response => {
      if (!response.ok) throw Error(response.status) // Fetch is dumb AF, and this is how you pass to the catch block
      return response.json()
    })
     .then((body) => {
       this.loaded = true
       this.setUserInfo(body)
       this.toggleLogin('login')
     })
     .catch(error => {
       if (error) {
         if (error == '401') {
           this.toggleLogin('logout')
           router.push({ path: 'get-access' })
         } else
         alert('Oh no! something went wrong!')
       }
     })
    //this.cleanURL()
  },
  methods: {
    setUserInfo(response) {
      this.user = response.user
      this.deployments = response.deployments
    },

    // move to some kind of Auth Mixin or something
    toggleLogin(action){
      switch (action) {
        case 'login':
          window.localStorage.setItem('spinupuseremail', this.email)
          window.localStorage.setItem('spinupuserplan', this.user.plan)
          this.$bus.trigger('authchange', '')
          break;
        case 'logout':
          window.localStorage.removeItem('spinupuseremail')
          window.localStorage.removeItem('spinupuserplan')
          this.$bus.trigger('authChange', '')
          window.location.href = '/'
          break;
        default:
      }
    },
    open() {
      if (window.localStorage.getItem('spinupuserplan') === 'free' && this.deployments.length == 1)  {
        this.showSubscribeModal = true
        return
      }
      window.location.href = "/"
    }
  }
}
</script>

<style>
.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1s linear infinite;
	animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
