<template id="">
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
          <div class="items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
            <button class="p-1 ml-auto border-0 text-black opacity-3 float-left text-3xl leading-none font-semibold outline-none  focus:outline-none" v-on:click="showModal = !showModal; postSuccessAndRedirect(10)">
              <span class="h-6 w-6 text-xl block outline-none focus:outline-none">
                x
              </span>
            </button>
          </div>
          <div class="relative p-6 flex-auto">
            <section
              class="flex flex-col lg:flex-row items-start items-center lg:justify-center w-full w-full lg:px-10 py-12 "
            >
              <article
                class="w-4/5 lg:w-custom mb-10 lg:px-4 py-10 text-center text-primary-dark bg-primary-white"
              >
                <h2 class="font-bold text-xl">Welcome to the SpinUp Standard Plan!</h2>
                <h2 class="pb-4 flex justify-center font-bold ">
                </h2>
                <ul class="text-sm font-bold">
                  <li class="pt-4 pb-4 ">If you have any questions or concerns, contact us at <a href="mailto:support@.spinup.dev">support@spinup.dev</a> </li>
                </ul>
              </article>
            </section>
            <!--Eventually: Or, just 5 dollars/mo for unlimited deploys, tunnels, APIs, password protection, and more.-->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</template>

<script charset="utf-8">
import requests from '@/requests/requests.js'
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      showModal: false
    }
  },
  created() {
    this.showModal = this.show
    this.postChargeSuccess()
    this.redirect(4000)
  },
  methods: {
    postChargeSuccess() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const q_params = Object.fromEntries(urlSearchParams.entries());
      requests.postSuccess(q_params['succeeded']).then((response) => response.json()).then((body) => {
        console.log(this.$route.params.email)
      })
    },
    redirect(timeout) {
      setTimeout(() => { 
        window.location.href = `/account/${this.$route.params.email}`
      }, timeout);
    }
  }
}
</script>
