<template>
  <div v-if="analytics" class="flex-1 mb-12 container">
    <MainGraph :deploy_link="deploy_link" :visitors="analytics.visitors" :general="analytics.general" :avg_visit_length="analytics.avg_visit_length" :requests="analytics.requests" class="relative w-full bg-white rounded shadow-xl dark:bg-gray-825 main-graph"/>
    <div class="items-start justify-between block w-full md:flex">
      <Referrals :referrals="analytics.referring_sites" class="stats-item flex flex-col w-full mt-6 stats-item--has-header"/> 
      <Devices :devices="analytics.os" class="stats-item flex flex-col w-full mt-6 stats-item--has-header"/>
    </div>
    <div class="items-start justify-between block w-full md:flex">
      <Browsers :browsers="analytics.browsers" class="stats-item flex flex-col w-full mt-6 stats-item--has-header"/>
      <Countries :geoData="analytics.geolocation" class="stats-item flex flex-col mt-6 stats-item--has-header w-full"/>
    </div>
  </div>
  <template v-if="!analytics">
    <div class="below-center z-50">
      <div class="animate-spin rounded-full h-32 w-32 border-b-8 border-green-500"></div>
    </div>
  </template>
</template>

<script>

import MainGraph from './analytics/MainGraph.vue'
import Referrals from './analytics/Referrals.vue'
import Devices from './analytics/Devices.vue'
import Browsers from './analytics/Browsers.vue'
import Countries from './analytics/Countries.vue'
import requests from '@/requests/requests.js'

export default {
  components: {
    MainGraph,
    Referrals,
    Devices,
    Browsers,
    Countries,
  },
  created() {
    if (!this.deploy_link) { 
      return
    }
      requests.getAnalytics(this.deploy_link).then(response => response.json())
        .then((body) => {
          this.analytics = body
        }).catch(function() {  
          console.log('le probleme avec lanalytics')
        });
  },
  data: function() {
    return {
      analytics: null
    }
  },
  props: {
    deploy_link: String
  }
};
</script>

<style scoped>

.container {
  max-width: 1000px;
}

.main-graph {
  height: 0;
  padding-top: calc(168px + 41.45221%);
  position: relative;
}
    
.stats-item {
  height: 27.25rem;
  margin-left: 6px;
  margin-right: 6px;
  min-height: auto;
  position: relative;
  width: calc(95% - 6px);
}

.below-center {
  margin: auto;
  position: fixed;
  top: 60%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
</style>
