<template>
  <div class="relative">
    <a href="https://twitter.com/SpinUpDev" target="_blank" class=""><i class="fa fa-twitter absolute left-0 top-0 pl-7"></i></a>
      <router-link to="/about" class="absolute right-0 top-0 pr-8">About</router-link>
  </div>
</template>

<script>

export default {
  data: function() {
    return {
    };
  },
  components: {
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
