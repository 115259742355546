<template id="">
  <div class="container pt-2">
    <div class="tabs">
        <a @click="activetab = '1'" v-bind:class="[ activetab === '1' ? 'active' : '' ]">HTML/CSS/JS</a>
        <a @click="activetab = '2'" v-bind:class="[ activetab === '2' ? 'active' : '' ]">React/Vue/Svelte/Angular</a>
        <!--<a @click="activetab = '3'" v-bind:class="[ activetab === '3' ? 'active' : '' ]">Vue  </a>-->
        <!--<a @click="activetab = '4'" v-bind:class="[ activetab === '4' ? 'active' : '' ]">Svelte</a>-->

        <!--TODO: change to logos-->

        <!--<a @click="activetab = '4'" v-bind:class="[ activetab === '4' ? 'active' : '' ]">Angular</a>-->
    </div>

    <div class="content text-left">
        <div v-if="activetab ==='1'" class="tabcontent flex-col">
          <section class="flex">
            <div class="pr-12">
              1.
            </div>
            <div class="flex-col">
              <div class="w-3/4">
                Upload an <code>index.html</code> file, along with any related files (.css, .js, etc.)
              </div>
            </div>
          </section>
          <section class="flex pt-2">
            <div class="pr-12">
              2.
            </div>
            <div class="flex-col">
              <div class="w-3/4">
                DEPLOY!
              </div>
            </div>
          </section>
        </div>

        <div v-if="activetab ==='2'" class="tabcontent flex-col">
          <section class="flex">
            <div class="pr-12">
              1.
            </div>
            <div class="flex-col">
              <div>
                if using npm, build your project:
              </div>
              <div class="pt-1">
                <code id="curl-create" class="curl-block text-base text-left relative">
                  <div class="pr-6">
                    $ npm run build
                  </div>
                </code>
              </div>
              <div>
                or with yarn:
              </div>
              <div class="pt-1">
                <code id="curl-create" class="curl-block text-base text-left relative">
                  <div class="pr-6">
                    $ yarn build
                  </div>
                </code>
              </div>
            </div>
          </section>
          <section class="flex">
            <div class="pr-12">
              2.
            </div>
            <div class="flex-col">
              <div class="w-3/4">
                Toggle from 'files' to 'directory', and upload the created <code>dist/</code> folder
              </div>
            </div>
          </section>
          <section class="flex pt-2">
            <div class="pr-12">
              3.
            </div>
            <div class="flex-col">
              <div class="w-3/4">
                DEPLOY!
              </div>
            </div>
          </section>
        </div>
    </div>
</div>

</template>
<script charset="utf-8">
  export default {
    props: {
    },
    data: function() {
      return {
        activetab: '1',
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<style>

/* RESET */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
    max-width: 620px;
    min-width: 420px;
    margin: 40px auto;
    font-size: 0.9em;
}

/* Style the tabs */
.tabs {
    overflow: hidden;
    margin-bottom: -2px; /* hide bottom border */
    margin-left: 24px;
}

.tabs a{
    float: left;
    cursor: pointer;
    padding: 12px 24px;
    transition: background-color 0.2s;
    border: 1px solid #ccc;
    border-right: none;
    background-color: #f1f1f1;
    border-radius: 6px 6px 0 0;
    font-weight: bold;
}
.tabs a:last-child {
    border-right: 1px solid #ccc;
}

/* Change background color of tabs on hover */
.tabs a:hover {
    background-color: #aaa;
    color: #fff;
}

/* Styling for active tab */
.tabs a.active {
    background-color: #fff;
    color: #484848;
    border-bottom: 2px solid #fff;
    cursor: default;
}

/* Style the tab content */
.tabcontent {
    padding: 30px 50px;
}

.tabcontent td {
  padding: 0.3em 0.4em;
  color: #484848;
}
.tabcontent td.legend {
  color: #888;
  font-weight: bold;
  text-align: right;
}
.tabcontent .map {
  height: 173px;
  width: 220px;
  background: #D3EAFB;
  margin-left: 60px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.data { width: 120px; }

.curl-block {
  background-color: #f7f7f7;
  line-height: 1.45em;
  font-size: 13px;
  margin-bottom: 1.1em;
  height: 100%;
  width: 170px;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.8em 1em;
  color: #1a1a1a;
  overflow: auto;
  display:inline-block;
  border-radius: 0.5rem;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}

code {
  border-radius: 0.05rem;
  font-family: Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace;
  background-color: #f7f7f7;
  box-shadow: 0 .33px 0 1px hsla(0,0%,100%,.15),0 6px 9px -4px rgba(0,0,0,.2),0 .4px 0 .8px rgba(0,0,0,.1);
}

</style>
