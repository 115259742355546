<template >
  <div class="text-left rounded overflow-hidden shadow-lg py-4 border border-gray-200">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{user.email}}</div>
    </div>
    <div class="px-6 flex flex-col">
      <span class="inline-block bg-grey-lighter rounded-full py-1 text-sm font-semibold text-grey-darker ">Account Created: {{formattedDateTime}}</span>
      <!--<span class="inline-block bg-grey-lighter rounded-full px-3 py-1 text-sm font-semibold text-grey-darker ">last login</span>-->
      <span class="inline-block bg-grey-lighter rounded-full py-1 text-sm font-semibold text-grey-darker">Plan: {{user.plan}} </span>
                                                              <!--TODO: next billing date, last date, cancel subscription (email verify)-->
      <button v-if="user.plan != 'standard'" @click="$emit('upgrade')" class="bg-green-400 rounded-full py-1 text-sm font-semibold underline">Upgrade for $3</button>

  <!--(upgrade)-->
    </div>
    <div class="py-2">
      <!--<button class="px-1 border-2 rounded-md">delete account</button>-->
    </div>
    <button @click="toggleLogin('logout')" class="mx-6 rounded border text-sm bg-white hover:bg-grey-300 text-gray-500 py-1 px-1 border-gray-300 rounded">logout</button>
    <div class="px-6 py-2 text-xs" >
      Need help? email us at support@spinup.dev
    </div>
  </div>
</template>
<script >
export default {
  props: {
    user: Object
  },
  computed: {
    formattedDateTime(){
      let datetimeString = this.user.created_at
      return (new Date(datetimeString)).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
    }

  },
  emits: ["upgrade"],
  methods: {
    toggleLogin(action){
      switch (action) {
        case 'login':
          window.localStorage.setItem('spinupuseremail', this.email)
          window.localStorage.setItem('spinupuserplan', this.user.plan)
          this.$bus.trigger('authchange', '')
          break;
        case 'logout':
          window.localStorage.removeItem('spinupuseremail')
          window.localStorage.removeItem('spinupuserplan')
          this.$bus.trigger('authChange', '')
          window.location.href = '/'
          break;
        default:
      }
    }
  }
}
</script>
